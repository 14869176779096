import React from 'react';
import qs from 'qs';
import moment from "moment";
import axios from 'axios';
import {LanguageTranslator} from "./LanguageTranslator";

export function filterURL(prefix, value) {
  if (value === '') {
      return;
  }
  if(Array.isArray(value)){
    value=value.join(',')
    if(value!=''){
      return 'in:' + value
    } else {
      return;
    }
  }
  if(prefix == "back_url"){
    return encodeURIComponent(value);
  }
  return value;
}


export const reduxToFriendlyUrl = (values) => {
  const result = {};
  Object.keys(values).forEach(function (key) {
    if (key.includes('$')) {
      result[key.replace(/\$/g, '.')] = values[key];
    } else {
      result[key] = values[key];
    }
  });
  return result;
};
export const urlToFriendlyRedux = (values) => {
  const result = {};
  Object.keys(values).forEach(function (key) {
    if (key.includes('.')) {
      result[key.replace(/\./g, '$')] = values[key];
    } else {
      result[key] = values[key];
    }
  });
  return result;
};

export const queryBuilder = queryData => {
  return qs.stringify(queryData, { filter: filterURL, encode: false });
};

export const parseURL = url => {
  const entries = qs.parse(url);
  let params = {}

  Object.keys(entries).forEach(function(key) {
    if(key!="") {
      let newValue=entries[key];
      if((typeof newValue === 'string' || newValue instanceof String) && newValue.startsWith("in:")){
        newValue=newValue.replace(/^in:/gi, "").split(',');
      }
      params[key] = newValue;
    }
  });
  return params;
}

export const CustomModal = ({handleClose, show, children}) => {
  const showHideClassName = show ? 'modal-react display-block' : 'modal-react display-none';

  return (
      <div className={showHideClassName}>
        <section className='modal-main'>
          {children}
        </section>
      </div>
  );
};

export const createCategoryQueryString = (url, category, defaultFilters) => {
  const queryData = qs.parse(url.slice(1))
  let keys = Object.keys(queryData);
  if(defaultFilters) {
    keys = keys.filter(k => (
      (k && typeof queryData[k] === "object") ||
      Object.keys(defaultFilters).includes(k)
    ))
  }

  const query = keys
    .map(k => {
      let value=queryData[k];
      if (value && typeof value === "object") {
        if(k == category) {
          return qs.stringify(value, { filter: filterURL, encode: false });
        } else {
          return ''
        }
      }
      return `${k}=${value}`;
    }).filter(v => v!="")
    .join("&");
  return query;
};

export function tryProperty(base, path) {
  var current = base;
  var components = path.split(".");
  for (var i = 0; i < components.length; i++) {
      if ((typeof current !== "object") || (current == null)) {
          return "";
      }
      current = current[components[i]];
  }
  return current;
}

function checkLuhn(purportedCC) {
  var len = purportedCC.length
  var sum = Number(purportedCC[len-1])
  var nDigits = len
  var parity = nDigits % 2;
  for(var i=0; i<=nDigits-2; i++) {
      var digit = Number(purportedCC[i])
      if(i % 2 == parity)
          digit = digit * 2
      if(digit > 9)
          digit = digit - 9
      sum = sum + digit
  }
  return (sum % 10 == 0)
}

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export function groupBy(data, key, keyType = 'date') {
  const groupedData = {};
  if (key && data && Array.isArray(data) && data.length > 0) {
    data.forEach((object) => {
      let groupByKey = null;
      if (keyType === 'date') {
        // eslint-disable-next-line prefer-destructuring
        groupByKey = object[key].split('T')[0];
      }
      if (!groupedData[groupByKey]) {
        groupedData[groupByKey] = [];
      }
      groupedData[groupByKey].push(object);
    });
  }
  return groupedData;
}

export function firstNFromHash(hash, limit) {
  const data = {};
  if (limit && limit > 0 && data && Object.keys(hash).length > 0) {
    Object.keys(hash).forEach((key, index) => {
      if (index < limit) {
        data[key] = hash[key];
      }
    });
  }

  return data;
}

export const masks = {
  phone: val => {
    val = val !== undefined ? val : "";
    // const cleaned = val.replace(/\D/g, '');
    // if(cleaned.length <= 10) {
    //   const x = cleaned.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    //   return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    // } else {
    //   const x = cleaned.match(/(\d{1,3})(\d{3})(\d{3})(\d{4})/);
    //   return '+' + x[1] + ' ' + (!x[3] ? x[2] : '(' + x[2] + ') ') + x[3] + (x[4] ? '-' + x[4] : '');
    // }

    var cleaned = ('' + val).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{1,3})(\d{0,3})(\d{0,4})(\d{0,9})/);
    if (match) {
      return ((!match[2] ? match[1] : '(' + match[1] + ') ') + match[2] + (match[3] ? '-' + match[3] : '') + (match[4] ? ' x' + match[4] : ''));
    }
    return '';
  },
  email: val => {
    val = val !== undefined ? val : "";
    return val.replace(/\s+/g, '');
  },
  date: (val = '', prevVal = '') => {
    // Currently supported format: MM/DD/YYYY
    const cleaned = `${val}`.replace(/\D/g, '').substring(0, 8);
    const match = cleaned.match(/^(\d{0,2})(\d{0,2})(\d{0,4})/);
    if (
      prevVal.length > val.length &&
      prevVal[prevVal.length - 1] === '/' // If user is deleting the '/' character
    ) {
      return val;
    }

    const lastCharacter = cleaned[cleaned.length - 1];
    switch (cleaned.length) {
      case 0:
        return '';
      case 1:
        if (lastCharacter === '0' || lastCharacter === '1') {
          return cleaned;
        }
        return `0${cleaned}/`;
      case 2:
        return `${cleaned}/`;
      case 3:
        if (lastCharacter <= 3) {
          return `${match[1]}/${match[2]}`;
        }
        return `${match[1]}/0${match[2]}/`;
      case 4:
        return `${match[1]}/${match[2]}/`;
      case 5:
      case 6:
      case 7:
      case 8:
        return `${match[1]}/${match[2]}/${match[3]}`;
      default:
        return prevVal;
    }
  },
  integer: val => {
    val = val !== undefined ? val : "";
    return val.replace(/\D/g, '');
  },
  currency: val => {
    val = val !== undefined ? val : "";
    var x = val.replace(/[^\.\d]/g, '');
    x = x.replace(/^\./, '');
    if(/\d+\.\d\d\d+$/.test(x) || /^\d{5}/.test(x)){
      x = x.slice(0, -1);
    }
    const newVal  = x ? '$' + x : '';
    return newVal;
  },
  npi: val => {
    val = val !== undefined ? val : "";
    const cleaned = val.replace(/\D+/g, '');
    const match = cleaned.match(/^(\d{0,10})/);
    if (match) {
      return match[1]
    }
    return '';
  },
  postal_code: (val) => {
    // eslint-disable-next-line no-use-before-define
    const filteredVal = validations.isNull(val) ? '' : val;
    const cleaned = filteredVal.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{1,5})(\d{0,4})/);
    if (match) {
      // eslint-disable-next-line no-use-before-define
      if (validations.isNotNull(match[2])) {
        return `${match[1]}-${match[2]}`;
      }
      return match[1];
    }
    return '';
  },
  ssnOrTin: (newVal, prevVal) => {
    if (prevVal !== '' && prevVal !== newVal && /^\*{5}\d{4}$/.test(prevVal)) {
      return '';
    }
    return newVal;
  },
  age: val => {
    val = val !== undefined ? val : "";
    const cleaned = val.replace(/\D+/g, '').replace(/^0+(?=\d)/, '');
    const match = cleaned.match(/^(\d{0,3})/);
    if (match) {
      return match[1]
    }
    return '';
  },
};

export const filters = {
  currency: (value) => /^(?:\d{0,6}\.?\d{0,2}?)?$/.test(value)
}

const letter = /[a-zA-Z]/;

export const validations = {
  isNotNull: (val) => {
    return (val !== null && val !== undefined && val !== '')
  },
  isNull: (val) => {
    return !(validations.isNotNull(val))
  },
  required: (value, locale = 'en') => {
    if (Array.isArray(value)) {
      return value.length > 0
        ? undefined
        : LanguageTranslator.translate(locale, 'valid_required');
    }
    if (typeof value === 'string' && value.trim() === '') {
      return LanguageTranslator.translate(locale, 'valid_required');
    }
    if (value || typeof value === 'number' || value === false) {
      return undefined;
    }
    return LanguageTranslator.translate(locale, 'valid_required');
  },
  validateUserName: (value) => {
    if (value && value.trim().includes(' ')) {
      return 'Please provide valid username.';
    }
    return null;
  },
  requiredCheckBox: (value, locale = 'en') => {
    if (value && value !== 'false') {
      return undefined;
    }
    return LanguageTranslator.translate(locale, 'valid_checkbox');
  },
  requiredSelect: (value) => {
    if(Array.isArray(value)) {
      return value.length > 0 ? undefined : 'Please select an item in the list.';
    } else if(value || typeof value === 'number' || value === false) {
      return undefined;
    } else {
      return 'Please select an item in the list.';
    }
  },
  phone: (value, locale = 'en') => {
    const normalisedValue = value === undefined || value == null ? '' : value;
    const pattern =
      /^(\+?\d{1,3}[- ]?)?\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})(?:\sx\d{1,9})?$/;
    const areaPattern = /^(\+?\d{1,3}[- ]?)?\(?([2-9]\d{2})\)?.*/;
    if (normalisedValue === '') return false;
    if (!pattern.test(normalisedValue))
      return LanguageTranslator.translate(locale, 'valid_phone_number');
    if (!areaPattern.test(normalisedValue))
      return LanguageTranslator.translate(locale, 'valid_area_code');
    return false;
  },
  email: (value, locale='en') => {
    // var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // const re = /^\s*(([/-9!#-'*+=?A-~-]+(?:\.[/-9!#-'*+=?A-~-]+)*|"(?:[^"\r\n\\]|\\.)*")@([0-9A-Za-z-]+[0-9A-Za-z]?(?:\.[A-Za-z][0-9A-Za-z-]*[0-9A-Za-z]?)*|\[(?:[^\[\]\r\n\\]|\\.)*\]))\s*$/
    // const re = /^(([/-9!#-'*+=?A-~-]+(?:\.[/-9!#-'*+=?A-~-]+)*|"(?:[^"\r\n\\]|\\.)*")@([0-9A-Za-z-]+[0-9A-Za-z]?(?:\.[A-Za-z]{2,64})|\[(?:[^\[\]\r\n\\]|\\.)*\]))$/
    const re = /^(([/-9!#-'*+=?A-~-]+(?:\.[/-9!#-'*+=?A-~-]+)*|"(?:[^"\r\n\\]|\\.)*")@([0-9A-Za-z-]+[0-9A-Za-z]?(?:\.[0-9A-Za-z-]+)*?(?:\.[A-Za-z]{2,64})|\[(?:[^\[\]\r\n\\]|\\.)*\]))$/

    if (value === '' || value === undefined || value === null || re.test(value)) {
      return undefined;
    }
    return LanguageTranslator.translate(locale, 'valid_email');
  },
  ssnOrTin: (value, allValues, meta, name) => {
    const additionalTinArray =
      allValues.site_tins && allValues.site_tins.length
        ? allValues.site_tins.map((obj) => obj.tin)
        : [];
    additionalTinArray.push(allValues.tin);
    const exists = additionalTinArray.filter(
      (item, index) => additionalTinArray.indexOf(item) !== index,
    );
    if (meta.dirty && !/^\*{5}\d{4}$/.test(value)) {
      const currentValue = value === undefined || value == null ? '' : value;
      const pattern = /^(9\d{2})([ -]?)([7]\d|8[0-8])([ -]?)(\d{4})$/; // ITIN regex
      const pattern1 = /^[1-9]\d?-\d{7}$/; // EIN regex
      if (
        /^\d{9}$/.test(currentValue) &&
        exists.includes(currentValue) &&
        name !== 'tin'
      ) {
        return 'This TIN already exists';
      }
      if (currentValue === '' || /^\d{9}$/.test(currentValue)) {
        return false;
      }
      return 'Please enter a valid TIN.';
    }
    return false;
  },
  dob: (value, locale = 'en') => {
    value = value !== undefined ? value : '';
    const re = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;
    if (value === '') return undefined;
    if (re.test(value)) {
      const date = moment(value, 'MM/DD/YYYY');
      if (date.isValid() && date.isSameOrBefore(moment())) {
        return undefined;
      }
    }
    return LanguageTranslator.translate(locale, 'valid_date');
  },
  max: (val, maxVal, locale = 'en') => {
    if (
      (val || typeof val === 'number') &&
      (maxVal || typeof maxVal === 'number') &&
      +val > +maxVal
    ) {
      return LanguageTranslator.translate(locale, 'valid_value');
    }
    return undefined;
  },
  min: (val, minVal, locale = 'en') => {
    if (
      (val || typeof val === 'number') &&
      (minVal || typeof minVal === 'number') &&
      +val < +minVal
    ) {
      return LanguageTranslator.translate(locale, 'valid_value');
    }
    return undefined;
  },
  maxLength: (length) => {
    const validator = (val) => {
      val = val !== undefined ? val : "";
      val.length <= length
       ? undefined
       : `maximum characters allowed is ${length}.`
    }
    return validator
  },
  currency: (val) => {
    val = (val === undefined || val === null) ? '' : val;
    if (val !== '' && !/^(\$)?([0-9]+(\.[0-9][0-9]([0-9])?)?)?$/.test(val)) {
      return 'Please enter a valid amount'
    };
  },
  max_currency: (val) => {
      if(val && val[0] == '$'){
        val = val.slice(1);
      }

      if (val == '' || val == undefined || val <= 1000) {
        return undefined
      } else {
        return 'Please enter an amount between 1 - 1,000'
      }
  },
  lower_currency_range: (val) => {
      if(val && val[0] == '$'){
        val = val.slice(1);
      }

      if (val == '' || val == undefined) {
        return undefined
      } else if(val > 0 && val < 1) {
        return 'Minimum $1';
      } else {
        return undefined
      }
  },
  comboFieldValidator: (value, allValues, props, name) => {
    console.log('comboFieldValidator', value, allValues, props, name);
    if(allValues[`${name}_checkbox`] && (!value && typeof value !== 'number'))
      return "Please fill out this field.";
  },
  npi: (val) => {
    val = val === undefined || val === null ? '' : val;
    if (val == '' || checkLuhn('80840' + val)) {
      return undefined;
    } else {
      return 'The value provided is not a valid NPI.';
    }
  },
  npiOrNa: (val) => {
    val = val === undefined || val === null ? '' : val;
    var re = /^\d{10}$/;
    const cleaned = val.replace(/[^a-zA-Z0-9_-]+/g, '').toLowerCase();
    if (cleaned == 'na' || (re.test(val) && checkLuhn('80840' + val))) {
      return undefined;
    } else {
      return 'The value provided is not a valid NPI.';
    }
  },
  image: (imageFile) => {
    if (imageFile) {
      if (imageFile.size) {
        // Get image size in kilobytes
        const fileSize = imageFile.size / 1024;
        if(fileSize > 50 || fileSize < 10){
          return `File size should be between 10kb and 50kb`;
        }
      }
      if (imageFile.height > 500 || imageFile.width > 1400 || imageFile.height < 130 || imageFile.width < 400) {
        return "Resolution must be between 1400*500 and 400*130.";
      }
    }
  },
  profile_picture: (imageFile) => {
    if (imageFile) {
      if (imageFile.size) {
        // Get image size in kilobytes
        const fileSize = imageFile.size / 1024;
        if (fileSize < 10 || fileSize > 500) {
          return `File size should be between 10kb and 500kb`;
        }
      }
      if (
        imageFile.height < 200 ||
        imageFile.width < 200 ||
        imageFile.height > 1500 ||
        imageFile.width > 1500
      ) {
        return 'Resolution must be between 1500*1500 and 200*200.';
      }
    }
    return undefined;
  },
  site_photo: (imageFile) => {
    if (imageFile) {
      if (imageFile.size) {
        // Get image size in kilobytes
        const fileSize = imageFile.size / 1024;
        if (fileSize < 10 || fileSize > 500) {
          return `File size should be between 10kb and 500kb`;
        }
      }
      if (
        imageFile.height < 200 ||
        imageFile.width < 200 ||
        imageFile.height > 1500 ||
        imageFile.width > 1500
      ) {
        return 'Resolution must be between 1500*1500 and 200*200.';
      }
    }
    return undefined;
  },
  ipName: (val) => {
    if(val.length > 100 || val.trim() == "") {
      return "Please enter a valid name. This name should be unique to this site and not exceed 100 characters.";
    }
  },
  ipAddress: (val) => {
    if(val){
      var expression = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;
      if (!expression.test(val)){
        return "Please enter valid IP Address";
      }
    }
  },
  fName: (val) => {
    if (val && !val.trim().match(letter)) {
      return 'First name should contain alphabets.';
    }
    return '';
  },
  lName: (val) => {
    if (val && !val.trim().match(letter)) {
      return "Last name can only contain letters, ', or -.";
    }
    return '';
  },
  mInitial: (val) => {
    if (val && !val.trim().match(letter)) {
      return 'Middle name should contain alphabets.';
    }
    return '';
  },
  preferredName: (val) => {
    if (val && !val.trim().match(letter)) {
      return 'Preferred name should contain alphabets.';
    }
    return '';
  },
  stage: (val) => {
    if (val && !(!isNaN(parseFloat(val)) && isFinite(val) && val.length <= 5)) {
      return 'Stage should be numeric and less than 5 character';
    }
    return '';
  },
  postal_code: (val, locale = 'en') => {
    const filteredVal = validations.isNull(val) ? '' : val;
    const pattern = /^\d{5}(?:[-]\d{4})?$/;
    if (filteredVal === '' || pattern.test(filteredVal)) {
      return false;
    }
    const cleaned = filteredVal.replace(/\D/g, '');
    return LanguageTranslator.translate(
      locale,
      cleaned.length < 5 ? 'valid_zip' : 'valid_zip_format',
    );
  },
  suffixes: (val, options, locale = 'en') => {
    if (
      validations.isNull(val) ||
      val.length === 0 ||
      validations.isNull(options) ||
      options.length === 0
    ) {
      return false;
    }
    const invalidOptions = options.filter((o) => o.is_valid_option === false);
    if (invalidOptions.length === 0) {
      return undefined;
    }
    let hasInvalidOption = false;
    for (let i = 0; i < val.length; i++) {
      const allInvalid = invalidOptions.filter((io) => io.value === val[i]);
      hasInvalidOption = allInvalid && allInvalid.length > 0;
      if (hasInvalidOption === true) break;
    }
    if (hasInvalidOption !== true) {
      return undefined;
    }
    return LanguageTranslator.translate(locale, 'valid_suffix');
  },
  honorific: (val, options, locale = 'en') => {
    if (
      validations.isNull(val) ||
      val.length === 0 ||
      validations.isNull(options) ||
      options.length === 0
    ) {
      return false;
    }
    const invalidOptions = options.filter((o) => o.is_valid_option === false);
    if (invalidOptions.length === 0) {
      return undefined;
    }
    const hasInvalidOption = invalidOptions.filter((io) => io.value === val);
    if (hasInvalidOption.length === 0) {
      return undefined;
    }
    return LanguageTranslator.translate(locale, 'valid_honorific');
  },
};

export const renderError = ({
  style,
  meta: { touched, error, warning }
}) => (
  <span style={style}>
    {touched &&
      ((error && <span className="error">{error}</span>) ||
        (warning && <span>{warning}</span>))}
  </span>
);

export const alertApiError = (error) => {
  const msg =
    error.response.data && error.response.data.message
      ? error.response.data.message
      : error;
  // eslint-disable-next-line no-alert
  alert(msg);
};

export const postMessage = (message, targetOrigin = '*') => {
  window.parent && window.parent.postMessage && window.parent.postMessage(message, targetOrigin)
};

export class Deferred {
  constructor() {
    this.promise = new Promise((resolve, reject)=> {
      this.reject = reject
      this.resolve = resolve
    })
  }
}

export const withOrdinalSuffix = (i) => {
  const j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + 'st';
  }
  if (j == 2 && k != 12) {
    return i + 'nd';
  }
  if (j == 3 && k != 13) {
    return i + 'rd';
  }
  return i + 'th';
};

export const stringifyNumber = (n) => {
  const special = ['zeroth', 'first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelvth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'];
  const deca = ['twent', 'thirt', 'fourt', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];

  if (n < 20) return special[n];
  if (n % 10 === 0) return deca[Math.floor(n / 10) - 2] + 'ieth';
  return deca[Math.floor(n / 10) - 2] + 'y' + special[n % 10];
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const nameToLabel = (name) => {
  if (typeof name !== 'string') {
    return name;
  }
  return name
    .replace('_', ' ')
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
};

export const getUserTimeZone = () => {
  return (
    Intl &&
    Intl.DateTimeFormat() &&
    Intl.DateTimeFormat().resolvedOptions() &&
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
};
export const getUserTimeZoneName = () => {
  return (
    getUserTimeZone() && getUserTimeZone().replace('_', ' ')
    // .match(/.*\/(.*)/)[1]
  );
};

export const renderTrack = ({ style, ...props }) => {
  return (
    <div
      className="scrollbar-track"
      style={{ ...style, width: '' }}
      {...props}
    />
  );
}
export const renderThumb = ({ style, ...props }) => {
  return (
    <div
      className="scrollbar"
      style={{ ...style, width: '', position: 'absolute' }}
      {...props}
    />
  );
}
export const rangeOfYears = (start, stop, step = 1) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => ({
    label: start + i * step,
    value: start + i * step,
  }));

export const recurrenceFrequencies = (formValues, original_event, start_date, addWeekdays=true, editMode=false) => {
  const day = moment(start_date, saveDateFormat).format('dddd');
  const by_day = moment(start_date, saveDateFormat).format('dd').toUpperCase();
  const position = Math.ceil(moment(start_date).date() / 7);
  // var by_days = [by_day]

  // if (formValues && formValues.rrule && formValues.rrule.show_days && formValues.by_day) {
  //   by_days = []
  //   by_days = daysFromSelection(formValues.by_day)
  // }
  // if (editMode && original_event && original_event.by_day_mapped) {
  //   by_days = []
  //   by_days = original_event.by_day_mapped
  // }
  // if (editMode && original_event && original_event.frequency && original_event.frequency.toLowerCase() === "daily") {
  //   // if it's Daily event then consider it weekly which has all the days selected
  //   by_days = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"]
  // }
  var originalRRule = null
  if (editMode && original_event) {
    originalRRule = rruleFromEvent(original_event)
  }

  var options = []
  if(day != 'Sunday' && day != 'Saturday') {
    options.push({
      value: "Every weekday (Monday to Friday)",
      label: 'Every weekday (Monday to Friday)',
      freq: 'weekly',
      byday: ['MO', 'TU', 'WE', 'TH', 'FR'],
      interval: null,
      bymonthday: null
    })
  }
  options.push({
    value: 'weekly_on_the_given_day',
    label: `Weekly on ${day}`,
    freq: 'weekly',
    byday: [by_day],
    interval: null,
    bymonthday: null
  })
  options.push({
    value: `Every other Week on ${day || 'these days'}`,
    label: `Every other Week on ${day}`,
    interval: 2,
    freq: 'weekly',
    byday: [by_day],
    bymonthday: null
  })
  if(position < 5) {
    options.push({
      value: `Monthly on the ${stringifyNumber(position)} ${day}`,
      label: `Monthly on the ${stringifyNumber(position)} ${day}`,
      freq: 'monthly',
      byday: [`${position}${by_day}`],
      interval: null,
      bymonthday: null
    })
  }
  if(!moment(start_date).isSame(moment(start_date).add(7, 'days'), 'month')) {
    options.push({
      value: `Monthly on the last ${day || 'given day'}`,
      label: `Monthly on the last ${day}`,
      freq: 'monthly',
      byday: [`-1${by_day}`],
      interval: null,
    })
  }
  if (editMode && originalRRule) {
    const optionAlreadyAvailable = repeatOptionAlreadyAvailable(
      options,
      originalRRule
    );
    if (!optionAlreadyAvailable) {
      options.push(originalRRule);
    }
  }
  options.push({
    value: 'custom',
    label: 'Custom Recurrence',
    freq: 'weekly',
    byday: [by_day],
    interval: 1,
    // bymonthday: null
  })
  return options
}

export const daysFromSelection = (values, abbreviations=true) => {
  var by_days = []
  if(values) {
    const days = Array.isArray(values) ? values : values.split(',');
    if (days && days.length > 0) {
      days.forEach((day) => {
        const filteredDays = byDaysList.filter((l) => l.value.toLowerCase().substring(0,2) === day.toLowerCase().substring(0,2))
        if (filteredDays && filteredDays.length > 0 && filteredDays[0]) {
          const filteredDay = filteredDays[0].value
          if (filteredDay) {
            if (abbreviations) {
              const filteredDayAbbreviation = filteredDay.substring(0, 2).toUpperCase();
              if (filteredDayAbbreviation && !(by_days.indexOf(filteredDayAbbreviation) >= 0)) {
                by_days.push(filteredDayAbbreviation)
              }
            } else {
              by_days.push(filteredDay)
            }
          }
        }
      })
    }
  }
  return by_days
}

export const rruleFromEvent = (event) => {
  if (event && event.recurring_event && event.sequence) {
    var by_days = event.sequence.by_day
      ? event.sequence.by_day.split(',')
      : event.sequence.by_month_day
      ? event.sequence.by_month_day.split(',')
      : null;

    var newOption = {
      value: event.repeat_details_simplified,
      label: event.repeat_details_simplified,
      freq: event.sequence.frequency,
      byday: by_days,
      interval: event.sequence.interval || 1,
      // bymonthday: event.sequence.by_month_day,
    }
    // if (event.frequency && event.frequency.toLowerCase() === "daily") {
    //   // if it's Daily event then consider it weekly which has all the days selected
    //   newOption.value = 'every_week_on_these_days'
    //   newOption.label = `Every week on these days`
    //   newOption.freq = "weekly"
    //   newOption.byday = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"]
    //   newOption.show_days = true
    //   newOption.is_daily_event = true
    // }

    return newOption
  }
  return null
}

export const repeatOptionAlreadyAvailable = (frequencies, newOption) => {
  return frequencies.find(
    (option) =>
      option.freq === newOption.freq &&
      // interval
      (option.interval === newOption.interval ||
        ((!newOption.interval || newOption.interval == 1) &&
          (!option.interval || option.interval == 1))) &&
      // string of monthly day
      (option.byday === newOption.byday ||
        // string of monthday
        (!newOption.byday && option.bymonthday === newOption.bymonthday) ||
        // For daily - no day fields
        (!option.byday &&
          !option.bymonthday &&
          !newOption.byday &&
          !newOption.bymonthday) ||
        // Array of days
        (Array.isArray(newOption.byday) &&
          Array.isArray(option.byday) &&
          newOption.byday.length == option.byday.length &&
          newOption.byday.every((day) => option.byday.includes(day))))
  );

  // var optionAlreadyPresent = []
  // optionAlreadyPresent = frequencies.find((f) => {
  //     const equalFreq = f.freq && newOption.freq && (f.freq.toLowerCase() === newOption.freq.toLowerCase())
  //     const equalDays = stringArraysEqual(f.byday, newOption.byday)
  //     const equalMonthDays = f.bymonthday == newOption.bymonthday;
  //     var equalIntervals = ((newOption.interval === undefined || newOption.interval === null) && (f.interval === undefined || f.interval === null))
  //     if (newOption.interval === null || newOption.interval === undefined) {
  //       newOption.interval = 1
  //     }
  //     if (f.interval === null || f.interval === undefined) {
  //       f.interval = 1
  //     }
  //     if (newOption.interval && f.interval) {
  //       equalIntervals = newOption.interval === f.interval
  //     }
  //     if (f.freq && f.freq.toLowerCase() === 'weekly' && f.show_days === true && equalFreq && newOption.byday && newOption.byday.length > 1) {
  //       // if the option selected was weekly and multiple days were selected then there is an option available for that
  //       // in current default repeat dropdown called `weekly on these days` so we will select that option.
  //       return true
  //     }
  //     return (equalFreq && equalDays && equalIntervals)
  //   }
  // )
  // return optionAlreadyPresent
}

export const stringArraysEqual = (arr1, arr2) => {
  if (arr1 && arr2 && Array.isArray(arr1) && Array.isArray(arr2)) {
    return JSON.stringify(arr1.sort().map(t => t.toLowerCase())) === JSON.stringify(arr2.sort().map(t => t.toLowerCase()))
  }
  return false
}

export const pick = (obj, keys = []) => {
  return keys.reduce(function (o, k) {
    if (obj.hasOwnProperty(k)) {
      o[k] = obj[k];
    }
    return o;
  }, {});
};

// show no more than 1 tooltip at a time
export const validateToolTips = (_class) => {
  // fix the issue: POL-15746
  $(`${_class}`).on('show.bs.tooltip', function () {
    let tooltips = $('.tooltip.fade.in');
    if (tooltips && tooltips.length > 0) {
      console.log(`tooltips count: ${tooltips.length}`);
      tooltips.first().remove();
    }
  })
}

export const encryptWith = (salt, iv, text) => {
  if (validations.isNull(text) || validations.isNull(salt)) {
    return '';
  }
  const cipher = crypto.createCipheriv('aes-256-cbc', salt, iv);
  let crypted = cipher.update(text, 'utf-8', 'base64');
  crypted += cipher.final('base64');
  return crypted;
};

export const decryptWith = (salt, iv, text) => {
  if (validations.isNull(text) || validations.isNull(salt)) {
    return '';
  }
  const cipher = crypto.createDecipheriv('aes-256-cbc', salt, iv);
  let crypted = cipher.update(text, 'base64', 'utf-8');
  crypted += cipher.final('utf-8');
  return crypted;
};

export const cloneArray = (arrayToBeCline) => {
  if (validations.isNotNull(arrayToBeCline)) {
    return arrayToBeCline.map((x) => ({ ...x }));
  }
  return [];
};

// Fast cloning with data loss.
// Use only if you do not have Dates, functions, undefined, Infinity, RegExps, Maps, Sets, Blobs, FileLists, ImageDatas, sparse Arrays, Typed Arrays or other complex types within your object.
// date: new Date(),  // stringified => "2023-06-19T07:59:47.856Z"
// undef: undefined,  // lost
// inf: Infinity,  // forced to 'null'
// re: /.*/,  // lost => {}
export const fastDeepClone = (object) => JSON.parse(JSON.stringify(object));

export const retainCursorPositionOnChange = (event, input) => {
  const v = validations;
  if (v.isNull(event) || v.isNull(input) || v.isNull(input.name)) {
    return;
  }
  const { name } = input;
  const fieldHasMasking =
    name &&
    [
      'postal_code',
      'phone_number',
      'site_phone_number',
      'alternate_phone_number',
      'alternate_phone_number_2',
      'fax',
      'fax_number',
      'dob',
    ].includes(name);
  if (fieldHasMasking !== true) {
    return;
  }
  const { target } = event;
  const { selectionStart, selectionEnd, value } = target;
  if (v.isNull(selectionStart) || v.isNull(selectionEnd)) {
    return;
  }
  // only reset if something was changed/delete in the
  // middle of string and not at the end(which means it was normal insert)
  const resetCursor = selectionStart !== (value || '').length;
  if (resetCursor) {
    setTimeout(() => {
      target.setSelectionRange(selectionStart, selectionEnd);
    }, 10);
  }
};

export const docsBaseURL = "https://polarishealth.atlassian.net/wiki/spaces/TRAIN/pages/1098252289";
export const userLicenseLink = `${docsBaseURL}/Tridiuum+Business+Associate+Agreement+and+Terms+of+Service#Tridiuum-ONE-User-Agreement`;
export const businessAssociateLink = `${docsBaseURL}/Tridiuum+Business+Associate+Agreement+and+Terms+of+Service#Business-Associate-Agreement`;
export const termsOfServiceLink = `${docsBaseURL}/Tridiuum+Business+Associate+Agreement+and+Terms+of+Service#Tridiuum-Inc-Terms-of-Service`;
export const privacyPolicyLink = `${docsBaseURL}/Tridiuum+Business+Associate+Agreement+and+Terms+of+Service#Privacy-Policy`;
export const helpMailLink = "help@tridiuum.com";
export const csrfHeader = {
  'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
};
export const saveDateFormat = "YYYY-MM-DD[T]HH:mm:ss";
export const crypto = require('crypto');
// export const byDaysList = [
//   {value: "sunday", label: "Sunday"},
//   {value: "monday", label: "Monday"},
//   {value: "tuesday", label: "Tuesday"},
//   {value: "wednesday", label: "Wednesday"},
//   {value: "thursday", label: "Thursday"},
//   {value: "friday", label: "Friday"},
//   {value: "saturday", label: "Saturday"},
// ]


export const endsOptions = [
  // { value: '', label: 'Never' },
  { value: 'after', label: 'After' },
  { value: 'on_date', label: 'On Date' },
];
export const affectsOptions = [
  {value: "single", label: "Only This Appointment"},
  {value: "all_future_events", label: "This and All Future Appointments"}
]
export const freqOptions = [
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
];
export const byDaysList = [
  {value: "SU", label: "S"},
  {value: "MO", label: "M"},
  {value: "TU", label: "T"},
  {value: "WE", label: "W"},
  {value: "TH", label: "T"},
  {value: "FR", label: "F"},
  {value: "SA", label: "S"},
]

export const calculateUTCOffset = (zone1, zone2) => {
  const utcOffset = moment().utcOffset();
  const offset1 = moment.tz(zone1).utcOffset() || utcOffset;
  const offset2 = moment.tz(zone2).utcOffset() || utcOffset;
  const netOffset = utcOffset + offset1 - offset2;
  return netOffset;
}

export const isIOS = () =>
  /iPad|iPhone|iPod/.test(navigator.platform) ||
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

export const isIOSMobile = () => /iPhone|iPod/.test(navigator.platform);

export const isMobile = () =>
  isIOSMobile() ||
  /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

export const putFile = (url, imageFile) =>
  axios.put(url, imageFile, {
    headers: {
      'Content-Type': imageFile.type,
    },
  });

export const deleteResource = (url) => axios.delete(url);


export const createIDFromText = (text, prefix = '') =>
  `${prefix}${String(text || '')
    .replace(/[^a-zA-Z0-9_]/g, '_')
    .toLowerCase()}`;

export const createOptionID = (text) =>
  createIDFromText(text, 'select-option--');


export function insertAtCursor(myField, myValue) {
  // IE support
  if (document.selection) {
    myField.focus();
    const sel = document.selection.createRange();
    sel.text = myValue;
    return myValue;
  }
  // MOZILLA and others
  if (myField.selectionStart || myField.selectionStart == '0') {
    const startPos = myField.selectionStart;
    const endPos = myField.selectionEnd;
    const newText =
      myField.value.substring(0, startPos) +
      myValue +
      myField.value.substring(endPos, myField.value.length);
    // eslint-disable-next-line no-param-reassign
    myField.value = newText;
    myField.selectionStart = startPos + myValue.length;
    myField.selectionEnd = startPos + myValue.length;
    return newText;
  }
  const newText = myField.value + myValue;
  // eslint-disable-next-line no-param-reassign
  myField.value = newText;
  return newText;
}

export const resizeImage = (
  imageObject,
  {
    maxSize = 1024 * 1024, // 1MB
    maxWidth = 1500,
    maxHeight = 1500,
    fileType = 'image/jpeg',
  } = {},
) => {
  let imageWidth = imageObject.width;
  let imageHeight = imageObject.height;

  if (imageWidth > imageHeight) {
    if (imageWidth > maxWidth) {
      imageHeight *= maxWidth / imageWidth;
      imageWidth = maxWidth;
    }
  } else if (imageHeight > maxHeight) {
    imageWidth *= maxHeight / imageHeight;
    imageHeight = maxHeight;
  }

  const canvas = document.createElement('canvas');
  canvas.width = imageWidth;
  canvas.height = imageHeight;

  const ctx = canvas.getContext('2d');
  ctx.drawImage(imageObject, 0, 0, imageWidth, imageHeight);

  return new Promise((resolve) => {
    function reduceImageSize(quality = 0.95) {
      canvas.toBlob(
        (blob) => {
          // eslint-disable-next-line no-param-reassign
          blob.width = imageWidth;
          // eslint-disable-next-line no-param-reassign
          blob.height = imageHeight;
          if (blob.size > maxSize) {
            reduceImageSize(quality * 0.95);
          } else {
            resolve(blob);
          }
        },
        fileType,
        quality,
      );
    }
    reduceImageSize();
  });
};

export const validURL = (str) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
};

export function showAgeRanges(treatmentAgeRanges, treatsAllAges) {
  if (validations.isNull(treatmentAgeRanges)) {
    return '';
  }
  var values = [];
  if (treatsAllAges) {
    return 'All Ages';
  }
  if (Array.isArray(treatmentAgeRanges)) {
    treatmentAgeRanges.forEach((treatmentAgeRange) => {
      if (validations.isNotNull(treatmentAgeRange.lower_bound) && validations.isNotNull(treatmentAgeRange.upper_bound)) {
         values.push(`${treatmentAgeRange.lower_bound} - ${treatmentAgeRange.upper_bound}`);
      } else if (validations.isNotNull(treatmentAgeRange.lower_bound) && validations.isNull(treatmentAgeRange.upper_bound)) {
        values.push(`${treatmentAgeRange.lower_bound}+`);
      }
    });
  }
  return values.join(', ');
};

export const fixTooltipPosition = (position) => {
  const screenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const screenHeight =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;
  const threshold = 20;
  return {
    top:
      position.top < threshold
        ? threshold
        : position.top > screenHeight
          ? screenHeight - threshold
          : position.top,
    left:
      position.left < threshold
        ? threshold
        : position.left > screenWidth
          ? screenWidth - threshold
          : position.left,
  };
};
import React from 'react';

const Label = ({
  input,
  label,
  showRequiredLabel,
  labelClass,
  icon,
  htmlFor,
  type,
}) => (
  <React.Fragment>
    {(label || type === 'checkbox') && (
      <label
        id={`field-label-${input.name.replace(/[^a-zA-Z0-9_]/g, '_')}`}
        className={labelClass || 'label-block'}
        htmlFor={htmlFor}
      >
        {showRequiredLabel ? <span className="required-star">*</span> : null}{' '}
        {icon && <img alt="" src={icon.src} style={icon.style} />}
        <span dangerouslySetInnerHTML={{ __html: label }}></span>
      </label>
    )}
  </React.Fragment>
);

export default Label;

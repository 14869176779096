import React from 'react';

import styles from './message.module.scss';

const Message = ({ message, tridiuumLogo, type = 'simple' }) => (
  <div className={`error-cont ${styles[type]}`}>
    <div className="form-wrapper" id="error-survey">
      <div className="symphony-logo-wrapper">
        <img
          src={
            tridiuumLogo
              ? `/assets/${tridiuumLogo.image}`
              : '/assets/polaris-landing-logo.png'
          }
          className={tridiuumLogo.class}
          alt="Tridiuum Logo"
          title={tridiuumLogo.title}
        />
      </div>

      {message && (
        <div className=" error-msg">
          <div className=" error-wrapper">
            <h3>{message}</h3>
          </div>
        </div>
      )}
    </div>
  </div>
);

export default Message;

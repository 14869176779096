import React from 'react';
import moment from "moment";
import {LanguageTranslator} from "../../LanguageTranslator";
import {validations} from "../../helperFunctions";


export const nextCharIndex = (array, type) => {
  var result = null;
  array.forEach((a, i) => {
    if (result === null) {
      if (type === "integer" && parseInt(a).toString() === a.toString()) {
        result = i;
      } else if (type === "alphabet" && a.match(/[a-zA-Z]/)) {
        result = i;
      }
    }
  });
  return result;
};

export const mssBreadcrumbs = (memberSelfServeId, stringValues) => {
  return [
    {
      name: 'Home',
      tag: 'home',
      goBack: false,
      url: `/member_self_serve/${memberSelfServeId}`,
    },
    { name: stringValues.provider_list, tag: 'provider_list', goBack: true, url: null },
    {
      name: stringValues.provider_detail,
      tag: 'provider_detail',
      goBack: false,
      url: null,
    },
    {
      name: stringValues.appointment_confirmation,
      tag: 'appointment_confirmation',
      goBack: false,
      url: null,
    },
  ];
};

export const formValidations = {
  isNotNull: (val) => {
    return (val !== null && val !== undefined && val !== '')
  },
  isNull: (val) => {
    return !(formValidations.isNotNull(val))
  },
  dob: (val, format, locale='en') => {
    const date = moment(new Date(val), format, true);
    if(alphabetsOnly.test(val) || date.isValid() === false || date.year() >= moment().year() || date.year() < moment(new Date("01/01/1900")).year()) {
      return LanguageTranslator.translate(locale, 'valid_date')
    }
  },
  dobYear: (val, locale='en') => {
    if (val && String(val).length < 4) {
      return LanguageTranslator.translate(locale, 'valid_year');
    } else if (val >= moment().year()) {
      return LanguageTranslator.translate(locale, 'valid_year');
    }
  },
  dobMonth: (val, locale='en') => {
    if (val > 12 || val <= 0) {
      return LanguageTranslator.translate(locale, 'valid_month');
    }
  },
  dobDay: (val, locale='en') => {
    if (val > 31 || val <= 0) {
      return LanguageTranslator.translate(locale, 'valid_day');
    }
  },
  name: (val, locale='en') => {
    if (!(/[a-zA-Z]/).test(val)) {
      return LanguageTranslator.translate(locale, 'valid_name');
    }
  },
  mrn: (val, regex, locale='en') => {
    if(val && regex) {
      if (!(new RegExp(regex).test(val))) {
        return LanguageTranslator.translate(locale, 'valid_mrn');
      }
    }
  },
  max: (val, limit, locale='en') => {
    if (val && val.length > limit) {
      return LanguageTranslator.translate(locale, 'valid_value');
    }
  },
  phoneNumber: (val, regex, locale='en') => {
    if (val && regex) {
      if (!(new RegExp(regex).test(val))) {
        return LanguageTranslator.translate(locale, 'valid_phone_number');
      }
    }
  },
  fieldErrorMessage: (locale = 'en') => {
    return LanguageTranslator.translate(locale, 'valid_required');
  },
  minValue: (val, limit, locale = 'en') => {
    if (formValidations.isNull(val) || !Number.isInteger(parseInt(val))) {
      return LanguageTranslator.translate(locale, 'valid_value');
    } else if (val < limit) {
      return LanguageTranslator.translate(locale, 'valid_min_value').replace("%{minValue}", limit);
    }
  },
  minValueWithBlankAllowed: (val, limit, locale = 'en') => {
    if (!formValidations.isNull(val) && val < limit) {
      return LanguageTranslator.translate(locale, 'valid_min_value').replace("%{minValue}", limit);
    }
  },
  validValue: (locale = 'en') => {
    return LanguageTranslator.translate(locale, 'valid_value');
  },
  email: (val, locale = 'en') => {
    return validations.email(val, locale);
  },
  brandingImage: (val, locale = 'en') => {
    return validations.image(val);
}


}

export const masks = {
  // only supports MM/DD/YYYY format
  dob: (val, format) => {
    val = val !== undefined ? val : "";
    var maskedValue = val;
    switch (format) {
      case "MM/DD/YYYY":
        const values = val.split("/");
        var [month, day, year] = values;
        month = masks.dobMonth(month && month.replace(/[^0-9]/g,''));
        day = masks.dobDay(day && day.replace(/[^0-9]/g,''));
        year = masks.dobYear(year && year.replace(/[^0-9]/g,''));
        maskedValue = `${month}/${day}/${year}`;
    }
    return maskedValue.substring(0,10);
  },
  dobMonth: (val) => {
    const format = "MM";
    if(val === undefined || val === null || val.length === 0) {
      return format
    }
    const intVal = parseInt(val);
    const stringVal = String(val);
    if (stringVal.length === 1 && (intVal >= 2)) {
      return `0${intVal}`;
    } else if (stringVal.length >= 2 && (intVal > 12 || intVal <= 0)) {
       return "12";
    } else if (val.length === 1) {
      var maskedMonth = format.split("");
      stringVal.split("").forEach((v, index) => {
        maskedMonth[index] = v;
      })
      return maskedMonth.join("").substring(0, 2);
    }
    return val;
  },
  dobDay: (val) => {
    const format = "DD";
    if (val === undefined || val === null || val.length === 0) {
      return format
    }
    const intVal = parseInt(val);
    const stringVal = String(val);
    if (stringVal.length >= 2 && (intVal > 31 || intVal <= 0)) {
      return format
    } else if ((stringVal.length < 2)) {
      var maskedDay = format.split("");
      stringVal.split("").forEach((v, index) => {
        maskedDay[index] = v;
      })
      return maskedDay.join("").substring(0, 2);
    }
    return val;
  },
  dobYear: (val) => {
    const format = "YYYY";
    if (val === undefined || val === null || val.length === 0) {
      return format
    }
    const intVal = parseInt(val);
    const stringVal = String(val);
    if ((stringVal.length < 4)) {
      var maskedYear = format.split("");
      stringVal.split("").forEach((v, index) => {
         maskedYear[index] = v;
      })
      return maskedYear.join("").substring(0, 4);
    } else if ((intVal >= moment().year()) || (intVal < moment(new Date("01/01/1900")).year())) {
      return moment().format('YYYY');
    }
    return val;
  }

}
export const alphabetsOnly = /[a-zA-Z]/g;
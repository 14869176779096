import React from 'react';
import wrapField from './wrapField';
import Prompt from './Prompt';
import FieldError from './FieldError';

const RadioGroupGrid = (props) => {
  const {
    field,
    meta: { error, touched, value },
    label,
    options,
    disabled,
    gridIndex,
    additionalInfo,
    moreText,
  } = props;

  return (
    <div className="fields-grid">
      {gridIndex === 0 && (
        <div className="option-ranges-box options-count-<%= options.count%>">
          {options.map((option) => (
            <div key={option.value} className="option-tab">
              {option.label}
            </div>
          ))}
        </div>
      )}

      <fieldset
        className="user-option-box user-option-box-noscroll clear"
        id="box-row<%= index + 1 %>"
      >
        <Prompt
          {...{ label, additionalInfo, moreText }}
          overrideClass="option-left-label"
        />
        <ul
          id="radio_label-<%= question.id %>"
          className={`list-left-label radio-<%= question.id%> ans-count-${options.length} rating-scale`}
        >
          {options.map((option, i) => (
            <li key={option.value} className="">
              <input
                {...field}
                name={field.name}
                type="radio"
                value={option.value.toString()}
                // eslint-disable-next-line eqeqeq
                checked={option.value == value}
                disabled={disabled}
                id={`${field.name}_option_${i}`}
              />
              <label htmlFor={`${field.name}_option_${i}`}>
                {option.label}
              </label>
            </li>
          ))}
        </ul>
      </fieldset>
      <FieldError {...{ error, touched }} />
    </div>
  );
};

export default wrapField(RadioGroupGrid);
